import { useState } from 'react'

import { ImageCarousel } from './image-carousel/ImageCoursel'

import { MapMarker } from '@/components/react/atoms/MapMarker'
import type { Destination } from '@/types/types'
import newZealand from '@/assets/images/new-zealand@2x.png'

const locations: Destination[] = [
  {
    text: 'Wellington',
    uri: '/new-zealand/nomads-wellington/',
    top: 47,
    right: 13,
    lineLength: 44,
  },
  {
    text: 'Queenstown',
    uri: '/new-zealand/nomads-queenstown/',
    top: 78,
    right: 75,
  },
]

export const NewZealandSectionHome = ({ hostels }) => {
  const [currentImageUri, setCurrentImageUri] = useState<string>(null)

  const getImageOnHover = (uri) => {
    setCurrentImageUri(uri)
  }
  return (
    <div className="mt-10 md:mt-[350px] flex relative items-center ">
      <div className="grid grid-cols-1 md:grid-cols-12 md:gap-8 items-center container  ">
        <div className="order-2 md:order-1 col-span-1 md:col-span-5 relative -mr-8 md:mr-0 md:ml-[-50%] max-w-[70%] md:max-w-none justify-self-start md:justify-self-end w-full hidden md:block">
          <ImageCarousel
            scrollToUri={currentImageUri}
            borderColor="border-teal"
            items={hostels.map((item) => ({
              title: 'New Zealand Hostels',
              subtitle: item.title,
              imageUrl: item.featuredImage.node.sourceUrl,
              srcSet: item.featuredImage.node.srcSet,
              altText: item.featuredImage.node.altText,
              uri: item.uri,
              id: item.uri,
            }))}
          />
        </div>
      </div>
      <div className="order-1 md:order-2 md:col-start-8 md:col-end-13 justify-self-end max-w-[95%] md:max-w-[30%] md:absolute right-0">
        <div className="relative w-fit">
          <img
            src={newZealand.src}
            loading="lazy"
            width={newZealand.width}
            height={newZealand.height}
            alt="New Zealand"
            className="object-cover w-[100%] lg:w-[400px] xl:w-[629px]"
          />
          {locations.map((location) => (
            <MapMarker
              text={location.text}
              uri={location.uri}
              top={location.top}
              right={location.right}
              lineLength={location.lineLength}
              getImageOnHover={getImageOnHover}
              key={location.uri}
              reverse
            />
          ))}

          <h3 className="absolute top-20 md:top-[23%] -left-16 md:right-[200px] lg:right-[300px] text-teal  text-4xl md:text-3xl lg:text-5xl 2xl:text-6xl 3xl:text-[52px] font-black w-1/2">
            New Zealand
          </h3>
        </div>
      </div>
    </div>
  )
}
